<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">메신저연동관리</h1>
    <div class="box-wrap d-flex">
      <div class="box-ct">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
        <div>
          <div class="form-inp sm">
            <v-select
                v-model.trim="formSearchData.ASP_NEWCUST_KEY"
                class="form-inp full"
                :items="userCompany"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                placeholder="선택하세요"
                required
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-6"
                  append-icon="svg-calendar"
                  label="조회기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="formSearchData.REG_DTTM_FROM"
                  no-title
                  dark
                  locale="ko-KR"
                  color="#F7AFAE"
                  :max="formSearchData.REG_DTTM_TO"
                  :first-day-of-week="1"
                  :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="formSearchData.REG_DTTM_TO"
                  dark
                  locale="ko-KR"
                  color="#F7AFAE"
                  :first-day-of-week="1"
                  no-title
                  :min="formSearchData.REG_DTTM_FROM"
                  :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
              >확인</v-btn
              >
            </div>
          </v-menu>
          <v-btn dark small class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px" plain @click="formSearchData.REG_DTTM_TO=''; formSearchData.REG_DTTM_FROM='';">
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
          <div class="form-inp ml-2">
            <v-text-field
                class="form-inp ml-4"
                name="name"
                label="고객사명"
                id="id"
                outlined
                hide-details
                v-model="formSearchData.SEARCH_COMPANY_NM"
                v-on:keyup.enter="result()"
            >
            </v-text-field>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
                class="form-inp ml-4"
                name="name"
                label="비즈서비스명"
                id="id"
                outlined
                hide-details
                v-model="formSearchData.SEARCH_BIZ_SERVICES_NM"
                v-on:keyup.enter="result()"
            >
            </v-text-field>
          </div>
          <div class="form-inp ml-2">
            <v-text-field
                class="form-inp ml-4"
                name="name"
                label="채널명"
                id="id"
                outlined
                hide-details
                v-model="formSearchData.SEARCH_TALK_SENDER_NM"
                v-on:keyup.enter="result()"
            >
            </v-text-field>
          </div>
        </div>
        </v-form>
      </div>
      <div class="ml-auto align-self-center">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="result">조회</v-btn>
      </div>
    </div>

    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        메신저연동관리
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" outlined class="btn-default" v-on:click="regist" >신규</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDetail')" outlined class="btn-default ml-2" v-on:click="getView" >상세</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" v-on:click="deleteInfo">삭제</v-btn>
        </div>
        <v-dialog
            v-if="dialogM810604P01"
            v-model="dialogM810604P01"
            max-width="655"
            :eager="true"
            content-class="square-modal min-auto"
        >
          <dialog-M810604P01
              headerTitle="메신저연동관리 신규생성"
              @hide="hideDialog('M810604P01')"
              @hideAndGetList="hideAndGetList('M810604P01')"
          >
          </dialog-M810604P01>
        </v-dialog>
        <v-dialog
            v-if="dialogM810604P02"
            v-model="dialogM810604P02"
            max-width="655"
            :eager="true"
            content-class="square-modal min-auto"
        >
          <dialog-M810604P02
              :param="detailData"
              headerTitle="메신저연동관리 상세"
              @hide="hideDialog('M810604P02')"
              @hideAndGetList="hideAndGetList('M810604P02')"
          >
          </dialog-M810604P02>
        </v-dialog>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            height="506px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="ROW_NUMBER"
            single-select
            fixed-header
            :page.sync="page"
            hide-default-footer
            class="grid-default case03 text-truncate"
            @page-count="pageCount = $event"
            @click:row="setListSel"
            @dblclick:row="showModalInfo"
            no-data-text="검색된 결과가 없습니다."
        >
          <template v-slot:[`item.PHONE_NUM`]="{ item }" >
            <div>{{ phoneHyphen(item.PHONE_NUM) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
        <p class="caption text-left">
          총
          <strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js"
import api from '@/store/apiUtil.js'
import {mapGetters} from "vuex";
import DialogM810604P01 from "./M810604P01.vue"
import DialogM810604P02 from "./M810604P02.vue"

let jsonObj

export default {
  name: "MENU_M810604", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    DialogM810604P01,
    DialogM810604P02
  },
  data: () => ({
    dialogM810604P01: false,
    dialogM810604P02: false,
    searchVo: function (){
      this.ASP_NEWCUST_KEY = ""
      this.REG_DTTM_FROM = ""
      this.REG_DTTM_TO = ""
      this.SEARCH_COMPANY_NM = ""
      this.SEARCH_BIZ_SERVICES_NM = ""
      this.SEARCH_TALK_SENDER_NM = ""
    },
    formSearchData: {},
    gridDataHeaders: [
      { text: "No", align: "left", value: "ROW_NUMBER", width: "50px", sortable: true },
      { text: "고객사ID", value: "ASP_CUST_INFO", align: "center", width: "100px", sortable: true },
      { text: "고객사명", value: "COMPANY_NM", align: "center", width: "150px", sortable: true },
      { text: "비즈서비스명", value: "BIZ_SERVICES_NM", align: "center", width: "140px", sortable: true },
      { text: "톡UUID", value: "TALK_UUID", align: "center", width: "150px", sortable: true },
      { text: "채널서비스상태", value: "SERV_KEEP_NM", align: "center", width: "100px", sortable: true },
      { text: "고객담당자명", value: "REGR_NM", align: "center", width: "160px", sortable: true },
      { text: "등록일시", value: "REG_DTTM", align: "center", width: "180px", sortable: true },
      { text: "연락처", value: "PHONE_NUM", align: "center", width: "130px", sortable: true },
      { text: "이메일", value: "EMAIL_ADDR", align: "center", width: "130px", sortable: true },
      { text: "위탁동의여부", value: "SERV_AGREE_YN", align: "center", width: "100px", sortable: true },
      { text: "위탁동의일시", value: "SERV_AGREE_DTTM", align: "center", width: "150px", sortable: true },
      { text: "채팅샌더키", value: "TALK_SENDER_KEY", align: "center", width: "250px", sortable: true },
      { text: "채팅채널명", value: "TALK_SENDER_NM", align: "center", width: "200px", sortable: true },
      { text: "인입URI", value: "BIZ_SERVICES_URI", align: "center", width: "200px", sortable: true }
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    totalVisible: 10,
    detailData: {},
    menu1: false,
    menu2: false,
    menu3: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    timeStep: '10:10',
    timeStep2: '10:10',
    isFormValid: true,
    selectedRow: [],
  }),
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.detailData = {}
      this.$refs.form.resetValidation()
      this.formSearchData = new this.searchVo()
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}/${month}/${day}`
    },
    formatSendDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}${month}${day}`
    },
    showModalInfo(event, { item }) {
      this.detailData = {}
      this.detailData = item
      this.showDialog("M810604P02");
    },
    setListSel(value, row) {
      if(value != null){
        this.activeRow(row,true);
      }
      this.detailData = value;
    },
    activeRow(row,value){
      this.selectedRow = row;
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    result() {
      if(this.isEmpty(this.formSearchData.ASP_NEWCUST_KEY)){
        this.common_alert('회사구분 은(는) 필수입력입니다.', 'error')
        return false
      }
      if(!this.$refs.form.validate()) return false
      this.apiCall('L', this.formSearchData)
    },
    setGridText(jsonData) {
      jsonObj = JSON.parse(jsonData);
      this.gridDataText = jsonObj;
    },
    deleteInfo(){

      if(this.isEmpty(this.detailData)){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.common_confirm('해당 정보를 삭제하시겠습니까?', this.deleteWord, '', this.closeAlert, '', 'error')
    },
    deleteWord(){
      let deleteData = [];
      let pushData = {
        ASP_NEWCUST_KEY: this.detailData.ASP_CUST_KEY,
        ASP_SENDER_KEY : this.detailData.ASP_SENDER_KEY
      }
      deleteData.push(pushData)
      let jsonData = {
        sendData: {
          DATA: deleteData
        }
      };
      this.apiCall('D', jsonData.sendData)
    },
    async apiCall(type, param){

      let URLData = ""
      let HEADER_SERVICE = ""
      let HEADER_TYPE = "BIZ_SERVICE"
      let HEADER_METHOD = ""
      let HEADER_ROW_CNT = 1000;
      let HEADER_PAGES_CNT = 1;

      if(type === 'D'){
        URLData = "/api/chat/setting/messenger-connect-manage/delete"
        HEADER_SERVICE = "chat.setting.messenger-connect-manage"
        HEADER_METHOD = "delete"
      }else{
        URLData = "/api/chat/setting/messenger-connect-manage/inqire"
        HEADER_SERVICE = "chat.setting.messenger-connect-manage"
        HEADER_METHOD = "inqire"
      }

      await api
          .post(URLData, param, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
              ROW_CNT: HEADER_ROW_CNT,
              PAGES_CNT: HEADER_PAGES_CNT
            },
          })
          .then((response) => {
            if(type === 'D'){
              //삭제
              if(!this.isEmpty(response) && !response.data.HEADER.ERROR_FLAG) {
                this.common_alert('정상처리 되었습니다.', 'done')
                this.detailData = {}
                this.result()
              } else {
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
              }
            }else{
              if(!this.isEmpty(this.selectedRow)){
                this.activeRow(this.selectedRow,false);
                //초기화
                this.selectedRow = [];
                this.detailData = {}
              }
              //조회
              this.setGridText(JSON.stringify(response.data.DATA));
            }
          })
          .catch((err) => {
            alert(err);
          });

    },
    isEmpty(val) {
      if (val == null) return true
      if (typeof val === 'undefined') return true
      if (typeof val === 'string' && val === '' && (''+val) === '') return true
      if (Array.isArray(val) && val.length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'Object' && Object.keys(val).length < 1) return true
      if (typeof val === 'object' && val.constructor.name === 'String' && Object.keys(val).length < 1) return true
      if (val.constructor === Object && Object.keys(val).length === 0) return true
    },
    closeAlert(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    regist(){
      this.showDialog("M810604P01")
    },
    hideAndGetList(type){
      this.hideDialog(type)
      this.result()
    },
    getView(){
      if(this.isEmpty(this.detailData)){
        this.common_alert('체크된 항목이 존재하지 않습니다.', 'error')
        return;
      }
      this.showDialog("M810604P02")
    },
    phoneHyphen(number){
      let phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
  },
  computed: {

    ...mapGetters({
      userCompany: 'userStore/GE_USER_COMPANY'
    }),

    dateRangeText() {
      let returnVal = "";
      if(!this.isEmpty(this.formSearchData.REG_DTTM_FROM) && !this.isEmpty(this.formSearchData.REG_DTTM_TO)){
        returnVal = this.formSearchData.REG_DTTM_FROM + " ~ " + this.formSearchData.REG_DTTM_TO;
      }else if(!this.isEmpty(this.formSearchData.REG_DTTM_FROM) && this.isEmpty(this.formSearchData.REG_DTTM_TO)){
        returnVal = this.formSearchData.REG_DTTM_FROM + " ~ "
      }else if(this.isEmpty(this.formSearchData.REG_DTTM_FROM) && !this.isEmpty(this.formSearchData.REG_DTTM_TO)){
        returnVal = " ~ " + this.formSearchData.REG_DTTM_TO;
      }
      return returnVal;
    },
    computedDateFormatted(){
      return this.formatDate(this.date)
    },
    computedDateFormatted2(){
      return `${this.date} ${this.timeStep}`
    }
  }
};
</script>
<style></style>
